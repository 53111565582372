import Image from 'next/image';
import {
  Header,
  Footer,
  Row,
  Col,
  Button as DarriusButton,
  Paragraph,
  StoreButton,
  Container,
} from 'darrius';

import { HeadContent } from '../../components';

import { type IRequestTemplateParams } from './@interfaces';
import { getBlurDataURL, imgPrefix } from '../../utils';

import styles from './RequestTemplate.module.scss';

const { Root: Button } = DarriusButton;

export const RequestTemplate = (props: IRequestTemplateParams) => {
  const {
    footerContent,
    meta,
    slug = '',
    hero,
    relatedCategories = null,
  } = props;

  const services = [
    {
      name: 'Assistência Técnica',
      slug: 'reformas-e-reparos/assistencia-tecnica',
    },
    { name: 'Diarista', slug: 'familia/diarista' },
    { name: 'Professor', slug: 'aulas' },
  ];

  const fraudText =
    "⚠️ <strong>Não caia em golpes! <a class='no-fraud-link text-link' href='https://www.getninjas.com.br/seguranca'target='_blank'>Acesse aqui</a> nossas instruções de segurança.</strong>";

  return (
    <>
      <div data-category-slug={slug}></div>

      <HeadContent
        metaTitle={meta.title}
        metaDescription={meta.description}
        pageType={meta.pageType}
        keywords={meta.keywords}
        noindex
      />

      <Header />

      <Container className={styles.container}>
        <div className={`${styles.section}`}>
          <Paragraph
            title="Agora falta pouco!"
            headline
            size="1"
            className={`${styles.title} no-margin`}
          />

          <Paragraph
            title="Baixe o app e valide seu pedido."
            headline
            size="2"
            className={`${styles.subtitle} no-margin`}
          />

          <Image
            src={imgPrefix('/images/ab-test/post-request/banner.svg')}
            alt={hero.heroImage.alt}
            title={hero.heroImage.alt}
            width={430}
            height={283}
            priority
            className={`responsive-image ${styles.image}`}
          />

          <div className={styles.status}>
            <Paragraph
              title="Como está o seu pedido:"
              size="3"
              className={styles['hows-your-request']}
            />

            <div>
              {hero.items.map(({ text, image }) => (
                <Row key={text} className={`start-sm top-sm ${styles.step}`}>
                  <Image
                    src={imgPrefix(image.replace('images', 'images/ab-test'))}
                    alt={text}
                    width={24}
                    height={60}
                  />

                  <span>{text}</span>
                </Row>
              ))}
            </div>

            <Paragraph
              text={fraudText}
              className={`${styles.warning} hide-md no-margin`}
              headline
            />
          </div>

          <Row className="center-sm hide-md post-request-hero__button">
            <Button
              as="a"
              href={
                'https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=pos-request&utm_medium=getninjas&utm_campaign=cli_teste_ab_CLA_1001&utm_content=b'
              }
            >
              Baixar o App
            </Button>
          </Row>
        </div>

        <div className={`hide-sm ${styles['qrcode-area']}`}>
          <div className={styles['qr-code-text']}>{hero.qrcode.text}</div>

          <Image
            src={imgPrefix('/images/ab-test/post-request/b.svg')}
            alt={hero.qrcode.image.alt}
            title={hero.qrcode.image.alt}
            width={195}
            height={195}
            placeholder="blur"
            blurDataURL={getBlurDataURL()}
            priority
          />

          <Paragraph
            text="Baixe o app para acompanhar <br> o seu pedido e falar com profissionais!"
            headline
            size="2"
            className={`no-margin ${styles['download-app']}`}
          />

          <Row>
            <Col className="col-sm-6">
              <StoreButton store="playstore" size="small" />
            </Col>
            <Col className="col-sm-6">
              <StoreButton store="appstore" size="small" />
            </Col>
          </Row>

          <Paragraph
            text={fraudText}
            className={`${styles.warning} hide-sm`}
            aria-hidden="true"
          />
        </div>
      </Container>

      {relatedCategories && (
        <div>
          <Paragraph
            title="Também pode te interessar"
            size="2"
            className={`${styles['might-interest']} no-margin`}
            headline
          />

          <Row
            className={`center-sm row--slider-sm ${styles['most-requested-services']}`}
          >
            {services.map((s, i) => (
              <a href={`/${s.slug}`} key={s.name} className={styles.service}>
                <Image
                  src={imgPrefix(`/images/ab-test/post-request/${i}.jpg`)}
                  alt={s.name}
                  width={250}
                  height={250}
                  placeholder="blur"
                  blurDataURL={getBlurDataURL()}
                  quality={100}
                  style={{
                    objectFit: 'contain',
                  }}
                />

                <div className={styles.service__details}>
                  <span className={styles.service__name}>{s.name}</span>

                  <span className={styles.service__hire}>Contrate agora</span>
                </div>
              </a>
            ))}
          </Row>
        </div>
      )}

      <Footer data={footerContent} />
    </>
  );
};
