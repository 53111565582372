import { useEffect, useState } from 'react';

import Image from 'next/image';

import {
  Header,
  Row,
  Col,
  Container,
  Banner,
  Button as DarriusButton,
} from 'darrius';

import { imgPrefix } from '../../utils';

import { Countdown, HeadContent, Loading, Toast } from '../../components';

import styles from './AuthTemplate.module.scss';
import { type IAuthProps } from '../../pages/requests/[uuid]/auth/user/[userId]/phone/[phoneId]';
import { useAuth } from '../../hooks/auth/useAuth';
import Otp, { type TOtpPinStatus } from '../../components/atoms/Otp/Otp';

const { Root: Button } = DarriusButton;

export const AuthTemplate = (props: IAuthProps) => {
  const [pin, setPin] = useState('');
  const [isPinSending, setIsPinSending] = useState(false);
  const [pinStatus, setPinStatus] = useState<TOtpPinStatus>('default');
  const [previouslyEnteredPin, setPreviouslyEnteredPin] = useState('');

  const { uuid, userId, phone, i18n } = props;

  const { subtitle, resendSms, title } = i18n;

  const { onSubmitPin, onResendPin, countDown } = useAuth();

  const {
    onResetCountdown,
    onCountdownOver,
    isShouldResetCountdown,
    isCountdownOver,
  } = countDown;

  useEffect(() => {
    const handlePin = async () => {
      if (pin.length === 4 && pin !== previouslyEnteredPin) {
        setPreviouslyEnteredPin(pin);
        setIsPinSending(true);

        const { success } = await onSubmitPin({
          phone: phone.onlyNumbers,
          userId,
          pin,
        });

        if (success) {
          setPinStatus('success');
          setIsPinSending(false);

          const timer = setTimeout(() => {
            window.location.assign(`/requests/${uuid}`);
          }, 2000);

          return () => {
            clearTimeout(timer);
          };
        }

        setPinStatus('error');
        setIsPinSending(false);
      }
    };

    handlePin();
  }, [onSubmitPin, phone.onlyNumbers, pin, previouslyEnteredPin, userId, uuid]);

  async function handleResendPin() {
    await onResendPin({
      phone: phone.onlyNumbers,
      userId,
    });
  }

  function handleChangePin(pinValue: string) {
    setPinStatus('default');
    setPin(pinValue);
  }

  return (
    <Container>
      <HeadContent
        metaTitle="GetNinjas | Validação de SMS"
        metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
        pageType="auth:show"
        noindex
      />

      <Header />

      <Banner>
        <Row>
          <Col className={styles.auth__content}>
            <Image
              src={imgPrefix('/images/auth/sms-code.svg')}
              alt=""
              height={96}
              width={96}
            />

            <Banner>
              <h1>{title}</h1>
              <p>
                {subtitle} <strong>{phone.formatted}</strong>
              </p>

              <Otp
                handleChangePin={handleChangePin}
                isPinSending={isPinSending}
                pinStatus={pinStatus}
              />

              {isPinSending && (
                <div className={styles.pin_loading}>
                  <Loading />
                </div>
              )}

              <Countdown
                onIsCountdownOver={onCountdownOver}
                timeToCountDown={resendSms.countdown.timeInSeconds}
                isShouldResetCountdown={{
                  canReset: isShouldResetCountdown,
                  onReset: onResetCountdown,
                }}
                textToShow={{
                  prefix: resendSms.countdown.text.prefix,
                  suffix: resendSms.countdown.text.suffix,
                }}
              />

              <Button
                onClick={handleResendPin}
                className={styles.resend_button}
                variation="clear"
                disabled={!isCountdownOver || isPinSending}
                size="small"
              >
                {resendSms.button.text}
              </Button>
            </Banner>
          </Col>
        </Row>
      </Banner>

      <Toast />
    </Container>
  );
};
