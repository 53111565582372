import { Paragraph } from 'darrius';
import styles from '../ChatListTemplate.module.scss';
import { Loading } from '../../../components';
import * as Card from '../Card/Card';
import { truncate } from '../../../utils';

export const Conversations = ({
  state,
  conversations,
  conversationInfoMessage,
  conversationId,
  handleConversationClick,
  errorMessage,
}) => {
  return (
    <>
      <Paragraph
        size="2"
        title="Mensagens"
        className={styles['section-title']}
      />
      <ul className={styles.list}>
        {conversations.length > 0 &&
          conversations.map((conversation) => (
            <Card.Root
              key={conversation.chatId}
              isSelected={conversationId === conversation.chatId}
              id={conversation.chatId}
              onClick={(event) => {
                event.preventDefault();
                handleConversationClick(conversation.chatId);
              }}
            >
              <Card.Avatar image={conversation.title} />
              <Card.Content>
                <Card.Title>{conversation.title}</Card.Title>
                <Card.Subtitle>{conversation.subtitle}</Card.Subtitle>
                <Card.Text>
                  {truncate(conversation.message, 80, '...')}
                </Card.Text>
              </Card.Content>
              {conversation.lastMessageSent && (
                <Card.Sidebar>
                  <Card.Text>{conversation.lastMessageSent}</Card.Text>
                  {conversation.isUnread ? (
                    <Card.Badge count={conversation.unreadMessages} />
                  ) : (
                    <Card.Text>
                      <Card.ReadIcon />
                    </Card.Text>
                  )}
                </Card.Sidebar>
              )}
            </Card.Root>
          ))}
        {conversationInfoMessage && (
          <p className={styles.message}>{conversationInfoMessage}</p>
        )}
        {state === 'loading' && (
          <div className={styles.message}>
            Carregando mensagens <Loading />
          </div>
        )}
        {state === 'error' && (
          <div className={styles.message}>
            {errorMessage ||
              'Tivemos um erro carregando a listagem. Por favor tente novamente mais tarde.'}
          </div>
        )}
      </ul>
    </>
  );
};
