import { Header, Row, Col, Paragraph, Container, Picture } from 'darrius';

import { imgPrefix } from '../../utils';

import { HeadContent } from '../../components';

export const PageDownTemplate = () => {
  return (
    <>
      <HeadContent
        metaTitle="GetNinjas | Estamos fora do ar"
        metaDescription="Estamos fora do ar"
        pageType="pages:page-down"
      />

      <Container>
        <Header />

        <Row className="space-top space-bottom space-between middle-md">
          <Col className="col-sm-3 col-md-offset-1">
            <Picture
              className="full"
              src={{
                sm: imgPrefix('/images/notFound/404.svg'),
                md: imgPrefix('/images/notFound/404.svg'),
                lg: imgPrefix('/images/notFound/404.svg'),
              }}
            />
          </Col>

          <Col className="col-sm-12 col-md-7">
            <Paragraph
              size="1"
              title="Estamos fora do ar"
              text="Desculpe, estamos trabalhando para lhe proporcionar uma experiência melhor."
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
