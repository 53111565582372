import { Paragraph } from 'darrius';
import styles from '../ChatListTemplate.module.scss';
import * as Card from '../Card/Card';
import { truncate } from '../../../utils';

export const Profiles = ({ profiles, userId, handleProfileClick }) => {
  return (
    <>
      <Paragraph
        size="2"
        title="Escolha um Pro Institucional"
        className={styles['section-title']}
      />
      <ul className={styles.list}>
        {profiles.length ? (
          profiles.map((profile) => (
            <Card.Root
              key={profile.id}
              isSelected={userId === profile.userId}
              id={profile.id}
              onClick={(event) => {
                event.preventDefault();
                handleProfileClick(profile.userId, profile.id);
              }}
            >
              <Card.Avatar image={profile.name} />
              <Card.Content>
                <Card.Title>{profile.name}</Card.Title>
                <Card.Subtitle>{profile.category}</Card.Subtitle>
                <Card.Text>
                  {truncate(profile.description, 80, '...')}
                </Card.Text>
              </Card.Content>
            </Card.Root>
          ))
        ) : (
          <div className={styles.message}>
            Nenhum profissional institucional encontrado
          </div>
        )}
      </ul>
    </>
  );
};
