import Image from 'next/image';
import {
  Button as DarriusButton,
  Col,
  Container,
  Header,
  Paragraph,
  Row,
} from 'darrius';

import { imgPrefix } from '../../utils';

import { HeadContent } from '../../components';

import styles from './ErrorTemplate.module.scss';
import Link from 'next/link';

interface IErrorTemplateProps {
  title: string;
  text: string;
}

const { Root: Button } = DarriusButton;

export function ErrorTemplate({ title, text }: IErrorTemplateProps) {
  return (
    <>
      <HeadContent
        metaTitle="GetNinjas | Problemas Técnicos"
        metaDescription="Problemas Técnicos"
        pageType="pages:error"
        noindex
      />

      <Container>
        <Header />

        <Row className={styles['error-content']}>
          <Col className="col-sm-12">
            <Image
              src={imgPrefix('/images/error/server-side-error-off.svg')}
              alt="Serviço não encontrado"
              width={280}
              height={280}
              className="responsive-image"
            />
          </Col>

          <Col className="col-sm-12 col-md-6 start-sm">
            <Paragraph size="2" title={title} text={text} headline />
          </Col>

          <Col className="col-sm-12 col-md-6 start-sm">
            <Link
              href="/?utm_source=error-page&utm_medium=button"
              passHref
              legacyBehavior
            >
              <Button size="small" as="a">
                Acessar Página Inicial
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
