import { Header, Breadcrumb, Container, Paragraph, Footer } from 'darrius';

import { type SitemapPageProps } from '../../modules/sitemap/@interfaces';

import { HeadContent, SitemapLinks } from '../../components';

export function SitemapTemplate(props: SitemapPageProps) {
  const {
    meta,
    headerContent,
    breadcrumbs,
    title,
    items,
    showAll,
    footerContent,
  } = props;

  return (
    <>
      <HeadContent
        metaTitle={meta.title}
        canonicalLink={meta.canonical}
        metaDescription={meta.description}
        pageType="directories/category:show"
      />

      <Header about={headerContent.about} signup={headerContent.signup} />

      <Container as="main" className="sitemap-page">
        <Breadcrumb breadcrumbs={breadcrumbs.items} />

        <script
          id="site-map-configs"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: breadcrumbs.structuredData,
          }}
        />

        <Paragraph title={title} />

        <SitemapLinks items={items} showAll={showAll} />
      </Container>

      <Footer data={footerContent} />
    </>
  );
}
