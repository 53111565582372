import { Paragraph } from 'darrius';
import { Session, Chatbox } from '@talkjs/react';
import styles from '../ChatListTemplate.module.scss';

export const Chat = ({ userId, conversationId, chatInfoMessage }) => {
  return (
    <>
      <Paragraph size="2" title="Chat" className={styles['section-title']} />
      {userId && (
        <Session
          appId={process.env.NEXT_PUBLIC_CHAT_APP_ID}
          userId={userId.toString()}
        >
          {conversationId.length > 0 && (
            <Chatbox
              conversationId={conversationId}
              theme={{
                name: 'app-rn',
                custom: { showProfileButton: false },
              }}
              style={{ width: '100%', height: '88%' }}
            ></Chatbox>
          )}
        </Session>
      )}
      {chatInfoMessage && <p className={styles.message}>{chatInfoMessage}</p>}
    </>
  );
};
