import { HeadContent } from '../../components';

import styles from './ChatListTemplate.module.scss';
import { Container, Header } from 'darrius';
import { useState } from 'react';

import { axiosInstance } from '../../utils/axiosInstance';
import { type IChatListProps } from '../../modules/chat/list/@interfaces';
import { AxiosError, type AxiosResponse } from 'axios';
import { Profiles } from './Profiles/Profiles';
import { Conversations } from './Conversations/Conversations';
import { Chat } from './Chat/Chat';

interface IConversations {
  chatId: string;
  title: string;
  subtitle: string;
  message: string;
  avatar: string;
}

type TStates = 'pristine' | 'loading' | 'error' | 'success';

export const ChatListTemplate = ({ profiles }: IChatListProps) => {
  const [userId, setUserId] = useState<number>(null);
  const [conversationId, setConversationId] = useState('');
  const [conversations, setConversations] = useState<IConversations[]>([]);

  const [state, setState] = useState<TStates>('pristine');
  const [errorMessage, setErrorMessage] = useState('');

  const hasNoConversations = state === 'success' && conversations.length === 0;
  const hasNoProfileSelected = state === 'pristine';

  const getConversationInfoMessage = () => {
    if (hasNoProfileSelected) {
      return 'Selecione um Pro Institucional para verificar suas mensagens';
    }

    if (hasNoConversations) {
      return 'Não há conversas para este profissional institucional';
    }

    return null;
  };
  const conversationInfoMessage = getConversationInfoMessage();

  const getChatInfoMessage = () => {
    if (!userId) {
      return 'Selecione um Pro Institucional para poder selecionar uma conversa';
    }

    if (conversationId.length <= 0) {
      return 'Selecione uma conversa para ver o chat';
    }

    return null;
  };

  const chatInfoMessage = getChatInfoMessage();

  const handleProfileClick = async (user: number, profileId: number) => {
    setState('loading');
    setErrorMessage('');
    setUserId(user);
    setConversationId('');
    setConversations([]);
    try {
      const { data } = await axiosInstance({
        url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/v1/chat/conversations`,
        params: {
          profileId,
          userId: user,
        },
        method: 'GET',
      });
      setState('success');
      setConversations(data as IConversations[]);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response != null) {
          const { status } = error.response as AxiosResponse;
          if (status === 404) {
            setState('success');
          }
          if (status === 401) {
            setState('error');
            setErrorMessage(
              'O token de autenticação do profissional mudou. Por favor, consulte o time de tecnologia para suporte'
            );
          }
        } else {
          setState('error');
        }
      }
      setConversations([]);
    }
  };

  const handleConversationClick = (conversation) => {
    setConversationId(conversation);
  };

  return (
    <>
      <HeadContent
        metaTitle="Chat | Lista"
        metaDescription="Lista de conversas do chat"
        canonicalLink=""
        pageType="chat:list"
        noindex
      />
      <Header />
      <div className={styles.background}>
        <Container>
          <main className={styles.main}>
            <section>
              <Profiles
                profiles={profiles}
                userId={userId}
                handleProfileClick={handleProfileClick}
              />
            </section>
            <section>
              <Conversations
                state={state}
                conversations={conversations}
                conversationId={conversationId}
                handleConversationClick={handleConversationClick}
                errorMessage={errorMessage}
                conversationInfoMessage={conversationInfoMessage}
              />
            </section>
            <section className={styles.section}>
              <Chat
                userId={userId}
                conversationId={conversationId}
                chatInfoMessage={chatInfoMessage}
              />
            </section>
          </main>
        </Container>
      </div>
    </>
  );
};
