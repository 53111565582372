import Image from 'next/image';

import {
  Col,
  Container,
  Footer,
  Header,
  List,
  ListItem,
  Paragraph,
  Row,
} from 'darrius';

import { HeadContent } from '../../components';

import { FaqChatCta } from './FaqChat/FaqChatCta/FaqChatCta';

import { imgPrefix } from '../../utils';

import { type IFaqHomeProps } from '../../modules/faq/pageProps/getFaqHomePageProps/@interfaces';

import styles from './FaqHomeTemplate.module.scss';

export const FaqHomeTemplate = (props: IFaqHomeProps) => {
  const { meta, headerContent, title, subtitle, links, footerContent, chat } =
    props;

  return (
    <>
      <HeadContent
        metaTitle={meta.title}
        metaDescription={meta.description}
        pageType="faq_home"
      />

      <Header {...headerContent} />

      <Container as="main" className="faq-home">
        <section className="main-section">
          <Row className="middle-sm">
            <Col className="col-sm-9">
              <Paragraph title={title} text={subtitle} />
            </Col>

            <Col className={`col-sm-3 ${styles['main-image']}`}>
              <Image
                src={imgPrefix('/images/faq/duvidas.png')}
                alt={title}
                width={179}
                height={243}
                priority
              />
            </Col>
          </Row>
        </section>

        <section className="links">
          <List>
            {links.map((link) => (
              <ListItem key={link.id} className={styles.link}>
                <a href={link.url} className="faq-question">
                  <h2>{link.text}</h2>
                </a>
              </ListItem>
            ))}
          </List>
        </section>

        <FaqChatCta chat={chat} />
      </Container>

      <Footer data={footerContent} />
    </>
  );
};
