import { Banner, Button as DarriusButton, Container, Paragraph } from 'darrius';

import styles from './DuplicatedRequestHero.module.scss';

import { type TDuplicatedRequestHeroProps } from './@interfaces';

const { Root: Button } = DarriusButton;

export function DuplicatedRequestHero({
  button,
  description,
  title,
}: TDuplicatedRequestHeroProps) {
  return (
    <Banner as="section" className={styles.warning}>
      <Paragraph title={title} text={description} size="2" headline />

      <Container>
        <Button
          as="a"
          href={button.link}
          className="duplicated-request-hero__button"
        >
          {button.text}
        </Button>
      </Container>
    </Banner>
  );
}
