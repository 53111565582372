import styles from '../ChatListTemplate.module.scss';
import { Avatar as DarriusAvatar } from 'darrius';
import { avatarFallback } from '../../../utils/form/utils/avatarFallback';
import classNames from 'classnames';

export const Root = ({ isSelected, onClick, id, children }) => {
  const anchorCardClassname = classNames(styles.card, {
    [styles['card--selected']]: isSelected,
  });

  return (
    <li className={styles.item}>
      <a className={anchorCardClassname} href={`/${id}`} onClick={onClick}>
        {children}
      </a>
    </li>
  );
};

export const Content = ({ children }) => (
  <div className={styles.card__content}>{children}</div>
);

export const Avatar = ({ image }) => (
  <DarriusAvatar
    alt={image}
    src={avatarFallback(image)}
    className={styles.card__picture}
    size={60}
  />
);

export const Title = ({ children }) => (
  <h3 className={styles.card__title}>{children}</h3>
);

export const Subtitle = ({ children }) => (
  <h4 className={styles.card__subtitle}>{children}</h4>
);

export const Text = ({ children }) => <p className={styles.card__body}>{children}</p>;

export const Sidebar = ({ children }) => <div className={styles.card__sidebar}>{children}</div>

export const Badge = ({ count }) => <p className={styles.card__badge}>{count}</p>;

export const ReadIcon = () => (
  <img
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMDAwMDEgMTYuMTdMNS41MzAwMSAxMi43QzUuMTQwMDIgMTIuMzEgNC41MTAwMSAxMi4zMSA0LjEyMDAxIDEyLjdDMy43MzAwMSAxMy4wOSAzLjczMDAxIDEzLjcyIDQuMTIwMDEgMTQuMTFMOC4zMDAwMSAxOC4yOUM4LjY5MDAxIDE4LjY4IDkuMzIwMDEgMTguNjggOS43MTAwMSAxOC4yOUwyMC4yOSA3LjcxMDAxQzIwLjY4IDcuMzIwMDEgMjAuNjggNi42OTAwMSAyMC4yOSA2LjMwMDAxQzE5LjkgNS45MTAwMSAxOS4yNyA1LjkxMDAxIDE4Ljg4IDYuMzAwMDFMOS4wMDAwMSAxNi4xN1oiIGZpbGw9IiM4MTkyOUYiLz4KPC9zdmc+Cg=="
    alt="Ícone indicando que as mensagens desta conversa estão lidas"
  />
);
