import { Header, Footer } from 'darrius';

import { HeadContent } from '../../components';

import { RequestFaqCallToAction } from './RequestFaqCallToAction/RequestFaqCallToAction';
import { DuplicatedRequestHero } from './DuplicatedRequestHero/DuplicatedRequestHero';
import { RequestMostSearchedServices } from './RequestMostSearchedServices/RequestMostSearchedServices';

import { type IRequestTemplateParams } from '../Request/@interfaces';

export const DuplicatedRequestTemplate = (props: IRequestTemplateParams) => {
  const {
    footerContent,
    headerContent,
    meta,
    slug = '',
    hero,
    mostSearchedServices,
    faqCallToAction,
  } = props;

  return (
    <>
      <div data-category-slug={slug}></div>

      <HeadContent
        metaTitle={meta.title}
        metaDescription={meta.description}
        pageType={meta.pageType}
        keywords={meta.keywords}
        noindex
      />

      <Header signup={headerContent.signup} />

      <DuplicatedRequestHero {...hero} />

      <RequestMostSearchedServices {...mostSearchedServices} />

      <RequestFaqCallToAction {...faqCallToAction} />

      <Footer data={footerContent} />
    </>
  );
};
