import Image from 'next/image';

import {
  Header,
  Container,
  Footer,
  Row,
  Col,
  Paragraph,
  Button as DarriusButton,
} from 'darrius';

import { Dialog, DialogLoading, HeadContent } from '../../components';

import { imgPrefix } from '../../utils';

import styles from './FaqDeleteAccount.module.scss';
import { Suspense, useState } from 'react';
import FaqDeleteAccountDialogTemplate from './FaqChat/FaqDeleteAccountDialogTemplate/FaqDeleteAccountDialogTemplate';
import { type IFaqDeleteAccountPageProps } from '../../modules/faqDeleteAccount/pageProps/@interfaces';

const { Root: Button } = DarriusButton;

export const FaqDeleteAccountTemplate = (props: IFaqDeleteAccountPageProps) => {
  const { config, deleteAccount, chat, headerContent, footerContent } = props;

  const [isFaqDeleteAccountDialogOpen, setIsFaqDeleteAccountDialogOpen] =
    useState(false);

  function handleOpenFaqDeleteAccountDialog() {
    setIsFaqDeleteAccountDialogOpen((prevState) => !prevState);
  }

  return (
    <>
      <HeadContent
        metaTitle={config.metaTitle}
        metaDescription={config.metaDescription}
        canonicalLink={config.canonicalURL}
        pageType="faq_account_deletion:show"
        noindex
      />

      <Header about={headerContent.about} signup={headerContent.signup} />

      <main>
        <Container as="section" className={styles['main-section']}>
          <Row className={styles['content--as-column']}>
            <Col className="col-sm-12 col-md-9">
              <Image
                src={imgPrefix(deleteAccount.image.src)}
                alt={deleteAccount.image.alt}
                width={106}
                height={106}
              />
              <Paragraph
                title={deleteAccount.title}
                text={deleteAccount.text}
                size="1"
              />
            </Col>
            <Col className="col-sm-12 col-md-5 ${styles.action">
              <Button
                as="a"
                href={deleteAccount.actions.keep.link}
                fullWidth
                variation="primary"
              >
                {deleteAccount.actions.keep.text}
              </Button>
            </Col>
            <Col className="col-sm-12 col-md-5 ${styles.action">
              <Button
                fullWidth
                variation="inverse"
                onClick={handleOpenFaqDeleteAccountDialog}
              >
                {deleteAccount.actions.delete.text}
              </Button>
            </Col>
          </Row>
        </Container>
        <Dialog.Root
          onOpenChange={handleOpenFaqDeleteAccountDialog}
          open={isFaqDeleteAccountDialogOpen}
        >
          {isFaqDeleteAccountDialogOpen && (
            <Suspense fallback={<DialogLoading />}>
              <FaqDeleteAccountDialogTemplate chat={chat} />
            </Suspense>
          )}
        </Dialog.Root>
      </main>

      <Footer data={footerContent} />
    </>
  );
};
