import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button as DarriusButton,
  Col,
  Container,
  Header,
  ProgressBar,
  Row,
} from 'darrius';

import {
  Shell,
  ShellHeader,
  ShellContent,
  ShellFooter,
} from '../../../../templates/Survey/Shell';

import { useForm } from '../../hooks/useForm';
import { useTemplate } from '../../hooks/useTemplate';

import { isEmpty } from '../../utils/isEmpty';
import { last } from '../../utils/last';
import { shallowEqual } from '../../utils/shallowEqual';

import { TestAndRender } from '../TestAndRender';

import { type TStepScope, type IStepsProps } from './@interfaces';
import { type TUiSchemaParam } from '../@interfaces';

const { Root: Button } = DarriusButton;

export function StepsComponent({
  schema,
  uiSchema,
  dataSchema,
  renderers,
  onSubmit,
}: IStepsProps) {
  const { form, onGoBackToInitialScreenAndResetForm } = useForm();
  const { handleTemplateChange } = useTemplate();

  const [activityStep, setActivityStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [buttonAdvanceText, setButtonAdvanceText] = useState('Próximo');
  const [buttonAdvanceDisable, setButtonAdvanceDisable] = useState(true);

  const progressBarMax = totalSteps + 1;
  const progressBarValue = activityStep + 1;

  const elements = useMemo(() => uiSchema.elements || [], [uiSchema.elements]);

  const reducer = useCallback(
    (acc: string[], next: TStepScope) => {
      const path = String(last(next.scope.split('/')));

      if (schema.required.includes(path)) {
        return acc.concat(path);
      }

      return acc;
    },
    [schema.required]
  );

  const getRequiredFields = useCallback(
    (element: any) => {
      if (element.elements) {
        return element.elements.reduce(reducer, []);
      }

      return reducer([] as string[], element);
    },
    [reducer]
  );

  const requiredFields = useMemo(
    () => elements.map(getRequiredFields),
    [elements, getRequiredFields]
  );

  useEffect(() => {
    function checkingIfEveryFieldsAreRequired() {
      const formKeys = Object.keys(form);

      if (requiredFields[activityStep]) {
        return !requiredFields[activityStep].every(
          (requiredField: string) =>
            formKeys.includes(requiredField) && !isEmpty(form[requiredField])
        );
      }

      return false;
    }

    const isRequired = checkingIfEveryFieldsAreRequired();

    setButtonAdvanceDisable(isRequired);
  }, [activityStep, form, requiredFields]);

  useEffect(() => {
    if (totalSteps === 0 && !isEmpty(elements)) {
      setTotalSteps(elements.length - 1);
    }
  }, [elements, totalSteps]);

  useEffect(() => {
    if (totalSteps !== 0) {
      setButtonAdvanceText(activityStep === totalSteps ? 'Enviar' : 'Próximo');
    }
  }, [activityStep, totalSteps]);

  const handleStepsAdvancement = useCallback(() => {
    if (totalSteps === activityStep) {
      onSubmit(form);
      handleTemplateChange('finished');

      return;
    }

    setActivityStep((oldStep) => oldStep + 1);
  }, [activityStep, form, handleTemplateChange, onSubmit, totalSteps]);

  const handleStepsReturn = useCallback(() => {
    if (activityStep === 0) {
      onGoBackToInitialScreenAndResetForm();
      return;
    }

    setActivityStep((oldStep) => oldStep - 1);
  }, [activityStep, onGoBackToInitialScreenAndResetForm]);

  if (isEmpty(elements)) {
    return null;
  }

  return (
    <Shell>
      <ShellHeader>
        <Container>
          <Header />
        </Container>
      </ShellHeader>
      <ShellContent>
        <ul>
          {elements.map((child, index) => {
            if (index !== activityStep) {
              return null;
            }

            return (
              <li key={child.id}>
                <TestAndRender
                  schema={schema}
                  dataSchema={dataSchema}
                  uiSchema={child}
                  renderers={renderers}
                />
              </li>
            );
          })}
        </ul>
      </ShellContent>
      <ShellFooter>
        <ProgressBar max={progressBarMax} value={progressBarValue} />
        <Container>
          <Row className="center-sm around-sm space-between">
            <Col className="col-sm-6 col-md-4">
              <Button
                className="secondary"
                onClick={handleStepsReturn}
                fullWidth
              >
                Voltar
              </Button>
            </Col>
            <Col className="col-sm-6 col-md-4">
              <Button
                className="primary"
                onClick={handleStepsAdvancement}
                isDisabled={buttonAdvanceDisable}
                fullWidth
              >
                {buttonAdvanceText}
              </Button>
            </Col>
          </Row>
        </Container>
      </ShellFooter>
    </Shell>
  );
}

export function stepsPropsAreEquals(
  prevPropsSteps: Readonly<IStepsProps>,
  nextPropsSteps: Readonly<IStepsProps>
) {
  return (
    shallowEqual(prevPropsSteps.schema, nextPropsSteps.schema) &&
    shallowEqual(prevPropsSteps.uiSchema, nextPropsSteps.uiSchema) &&
    shallowEqual(prevPropsSteps.dataSchema, nextPropsSteps.dataSchema) &&
    JSON.stringify(prevPropsSteps.renderers) ===
      JSON.stringify(nextPropsSteps.renderers) &&
    JSON.stringify(prevPropsSteps.onSubmit) ===
      JSON.stringify(nextPropsSteps.onSubmit) &&
    JSON.stringify(prevPropsSteps.onGoBack) ===
      JSON.stringify(nextPropsSteps.onGoBack)
  );
}

export const Steps = memo(StepsComponent, stepsPropsAreEquals);

export const StepsTester = (uiSchema: TUiSchemaParam) =>
  uiSchema.type === 'Steps';
