import { useCallback } from 'react';

import {
  Avatar,
  Paragraph,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from 'darrius';

import { useForm } from '../../hooks/useForm';

import { avatarFallback } from '../../utils/avatarFallback';

import styles from './List.module.scss';

import { type TUiSchemaParam } from '../@interfaces';
import { type IListProps } from './@interfaces';

export function List({
  uiSchema,
  dataSchema,
  schema,
  path,
  handleChange,
}: IListProps) {
  const { form } = useForm();

  const ownProps = {
    title: schema.title,
    options: dataSchema,
    maxItems: schema.maxItems,
    ...uiSchema.options,
  };

  const onChange = useCallback(
    (optionId: string | number) => {
      handleChange(path, optionId);
    },
    [handleChange, path]
  );

  return (
    <Container>
      <Row className="center-sm">
        <Col className="col-sm-12 col-md-6">
          <Paragraph size="2" title={ownProps.title} />
          <ul className={styles.list}>
            {ownProps.options.map((option) => (
              <li className={styles.list__item} key={option.id}>
                <button
                  type="button"
                  onClick={() => {
                    onChange(option.id);
                  }}
                  className={styles.list__button}
                >
                  <Card className={`${styles['card-container']}`}>
                    <CardBody
                      className={`${styles['pro-card']} ${
                        form[path] === option.id
                          ? styles['pro-card--selected']
                          : ''
                      }`}
                    >
                      <Row>
                        <Col className="col-sm-4">
                          <Avatar
                            src={option.image}
                            alt={option.name}
                            className={styles['pro-avatar']}
                            fallback={avatarFallback(option.name)}
                            size={60}
                          />
                        </Col>
                        <Col className="col-sm-8">
                          <Row className="start-sm">
                            <Paragraph
                              size="4"
                              title={option.name}
                              text={option.category}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </button>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export const ListTester = (uiSchema: TUiSchemaParam) =>
  uiSchema.type === 'List';
