import { useCallback, useEffect, useState } from 'react';

import { Stars, Paragraph, Container, Row, Col, Avatar } from 'darrius';

import { avatarFallback } from '../../utils/avatarFallback';

import { useForm } from '../../hooks/useForm';

import styles from './Rating.module.scss';

import { type IRatingProps } from './@interfaces';
import { type TUiSchemaParam } from '../@interfaces';

export function Rating({
  uiSchema,
  dataSchema,
  schema,
  path,
  handleChange,
}: IRatingProps) {
  const { form, removePath } = useForm();

  const [rating, setRating] = useState(0);

  const currentProfessional = dataSchema.find(
    (profile) => profile.id === form.professional
  );

  const ownProps = {
    title: schema.title,
    ...uiSchema.options,
  };

  useEffect(() => {
    if (form[path]) {
      setRating(form[path]);
    }
  }, [form, path]);

  const handleClick = useCallback(
    (ratingValue: number) => {
      if (ratingValue === 0 || ratingValue === form[path]) {
        setRating(0);
        removePath(path);

        return;
      }

      handleChange(path, ratingValue);
    },
    [form, handleChange, path, removePath]
  );

  return (
    <Container>
      <Row className="center-sm space-between">
        <Col className="col-sm-12 col-md-8">
          <Row className="center-sm space-between">
            <Col className="col-sm-12 col-md-8">
              <Avatar
                src={currentProfessional.image}
                alt={currentProfessional.name}
                className={styles['pro-avatar']}
                fallback={avatarFallback(currentProfessional.name)}
              />
            </Col>
          </Row>
          <Paragraph size="3" title={currentProfessional.name} />
          <Row className="center-sm space-between">
            <Stars
              isInteractive
              rating={rating}
              quantity={5}
              size="large"
              onClick={handleClick}
            />
          </Row>
          <Paragraph size="4" text={ownProps.title} />
        </Col>
      </Row>
    </Container>
  );
}

export const RatingTester = (uiSchema: TUiSchemaParam) =>
  uiSchema.type === 'Rating';
