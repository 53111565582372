import Image from 'next/image';

import { BsWhatsapp } from 'react-icons/bs';

import {
  Header,
  Row,
  Col,
  Paragraph,
  Container,
  TextLink,
  Banner,
  Button as DarriusButton,
} from 'darrius';

import { usePhoneVerification } from '../../context/PhoneVerificationContext/PhoneVerificationContext';

import { imgPrefix } from '../../utils';

import { Countdown, HeadContent, PinInput, Dialog } from '../../components';

import ChangeNumberModal from './ChangeNumberModal/ChangeNumberModal';

import styles from './PhoneVerificationTemplate.module.scss';

const { Root: Button } = DarriusButton;

export const PhoneVerificationTemplate = () => {
  const { values: phoneVerificationValues, methods: phoneVerificationMethods } =
    usePhoneVerification();

  const {
    phoneVerificationTitle,
    phoneVerificationData,
    isPinInvalid,
    isPinSending,
    isCountdownOver,
    isShouldResetCountdown,
  } = phoneVerificationValues;

  const {
    onIsCountdownOver,
    onCountdownIsReset,
    onPinSubmit,
    onOpenChangeNumberModal,
    handleResendPin,
  } = phoneVerificationMethods;

  const {
    subtitle,
    inputPlaceholder,
    resend,
    sendButton,
    pinErrorMessage,
    initialPinValue,
    countdown,
  } = phoneVerificationData;

  return (
    <Container>
      <HeadContent
        metaTitle="GetNinjas | Validação de SMS"
        metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
        pageType="phone-verification:show"
      />

      <Header />

      <Dialog.Root onOpenChange={onOpenChangeNumberModal}>
        <Banner>
          <Row>
            <Col className={styles.phoneVerification__content}>
              <Container className="hide-sm">
                <Image
                  src={imgPrefix('/images/phone-verification/celphone.svg')}
                  alt="Icone Exibindo um Celular Dentro de uma Caixa"
                  title="Icone Exibindo um Celular Dentro de uma Caixa"
                  height={77}
                  width={77}
                />
              </Container>

              <Banner>
                <Paragraph
                  title={phoneVerificationTitle}
                  size="2"
                  text={subtitle}
                  headline
                />

                <PinInput
                  placeholder={inputPlaceholder}
                  initialValue={initialPinValue}
                  onSubmitPin={onPinSubmit}
                  pinValidation={{
                    isInvalid: isPinInvalid,
                    errorMessage: pinErrorMessage,
                  }}
                />

                <Countdown
                  onIsCountdownOver={onIsCountdownOver}
                  timeToCountDown={countdown.timeInSeconds}
                  isShouldResetCountdown={{
                    canReset: isShouldResetCountdown,
                    onReset: onCountdownIsReset,
                  }}
                  textToShow={{
                    prefix: countdown.text.prefix,
                    suffix: countdown.text.suffix,
                  }}
                />

                <Button
                  onClick={() => handleResendPin('sms')}
                  className={styles.phoneVerification__content_button}
                  variation="clear"
                  disabled={!isCountdownOver || isPinSending}
                  isLoading={isPinSending}
                  size="small"
                >
                  {sendButton}
                </Button>
              </Banner>

              <Row className="center-sm">
                <Col
                  className={`col-sm-12 ${styles.phoneVerification__footer}`}
                >
                  <Container className={styles.phoneVerification__footer_phone}>
                    <Paragraph text={resend.default.title} size="3" headline />

                    <Dialog.Trigger>
                      <TextLink text={resend.default.button.text} url="#" />
                    </Dialog.Trigger>
                  </Container>

                  <Container
                    className={styles.phoneVerification__footer_whatsapp}
                  >
                    <BsWhatsapp size={14} />
                    <Paragraph text={resend.whatsapp.title} size="3" headline />
                  </Container>

                  <TextLink
                    text={resend.whatsapp.button.text}
                    url="#"
                    onClick={() => handleResendPin('whatsapp')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Banner>

        <ChangeNumberModal />
      </Dialog.Root>
    </Container>
  );
};
