import Image from 'next/image';

import { Button, Col, Paragraph, Picture, Row } from 'darrius';

import { Dialog } from '../../../components';

import { getBlurDataURL, imgPrefix, triggerEvent } from '../../../utils';

import styles from './ContactProDialog.module.scss';

export const ContactProDialog = ({ id, requestId, index }) => {
  const defaultImageSize = {
    lg: {
      width: 180,
      height: 180,
    },
    md: {
      width: 180,
      height: 180,
    },
    sm: {
      width: 110.29,
      height: 109.95,
    },
  };

  return (
    <Dialog.Root>
      <div
        className={`instant-matching-button ${styles['instant-matching-button']}`}
      >
        <Dialog.Trigger>
          <Button.Root
            as="a"
            variation="clear"
            size="small"
            fullWidth
            onClick={() =>
              triggerEvent('instant-match-profile-list__contact-pro', {
                id,
                requestId,
                index,
              })
            }
          >
            <Button.IconButton size={24} name="MdOutlineChat" />
            Entrar em contato
          </Button.Root>
        </Dialog.Trigger>
      </div>

      <Dialog.Portal>
        <div className="instant-matching modal-interaction__dialog">
          <Dialog.Content>
            <div className={`modal_interaction ${styles.modal_interaction}`}>
              <Row className="hide-sm">
                <Col>
                  <Paragraph
                    text="Abra sua câmera e escaneie."
                    size="3"
                    headline
                  />
                </Col>
              </Row>

              <Row className="middle-sm">
                <Col className="col-md-12 center">
                  <Picture
                    className="picture"
                    src={{
                      sm: imgPrefix(
                        '/images/contato-instantaneo/img_instant-matching-contact-pros-mobile.svg'
                      ),
                      md: imgPrefix(
                        '/images/contato-instantaneo/qrcode-contact-pros-desktop.svg'
                      ),
                      lg: imgPrefix(
                        '/images/contato-instantaneo/qrcode-contact-pros-desktop.svg'
                      ),
                    }}
                    size={defaultImageSize}
                    alt="Baixe o App"
                  />
                </Col>
              </Row>

              <Row className="hide-md">
                <Col>
                  <Paragraph
                    title="Agora falta pouco!"
                    text="Baixe o app e converse com o profissional pelo chat."
                    size="3"
                    headline
                  />
                </Col>
              </Row>

              <Row className="hide-md">
                <Col>
                  <br />
                  <Button.Root
                    as="a"
                    href="https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=ci_web&utm_medium=getninjas&utm_campaign=entrar_em_contato"
                    className="primary close"
                    fullWidth
                    onClick={() =>
                      triggerEvent('instant-match-profile-list__download-app', {
                        id,
                        requestId,
                        index,
                      })
                    }
                  >
                    Baixar o App
                  </Button.Root>
                </Col>
              </Row>

              <Row className={`${styles.modal_interaction__paragraph} hide-sm`}>
                <Col>
                  <Paragraph
                    text="<strong>Baixe o app</strong> e converse com o profissional pelo chat."
                    size="1"
                    headline
                  />
                </Col>
              </Row>
              <Row className="hide-sm center">
                <Col className="center">
                  <Image
                    src={imgPrefix(
                      '/images/contato-instantaneo/img_instant-matching-stores.svg'
                    )}
                    alt="Baixe o app"
                    width={193.49}
                    height={40}
                    placeholder="blur"
                    blurDataURL={getBlurDataURL()}
                    priority
                  />
                </Col>
              </Row>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
