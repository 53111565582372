import Image from 'next/image';

import { Button, Col, Paragraph, Picture, Row } from 'darrius';

import { Dialog } from '../../../components';

import { getBlurDataURL, imgPrefix, triggerEvent } from '../../../utils';

import styles from './SkipIndicationsDialog.module.scss';

export const SkipIndicationsDialog = ({ requestId }) => {
  const defaultImageSize = {
    lg: {
      width: 180,
      height: 180,
    },
    md: {
      width: 180,
      height: 180,
    },
    sm: {
      width: 106,
      height: 106,
    },
  };

  return (
    <Dialog.Root>
      <div
        className={`instant-matching-button ${styles['instant-matching-button']}`}
      >
        <Dialog.Trigger>
          <Button.Root
            as="a"
            variation="clear"
            fullWidth
            onClick={() =>
              triggerEvent('instant-match-profile-list__skip-list', {
                requestId,
              })
            }
          >
            Não gostei das indicações
          </Button.Root>
        </Dialog.Trigger>
      </div>

      <Dialog.Portal>
        <div className="instant-matching modal-interaction__dialog">
          <Dialog.Content>
            <div className={`modal_interaction ${styles.modal_interaction}`}>
              <Row
                className={`${styles.modal_interaction__paragraph_desktop} hide-sm`}
              >
                <Col>
                  <Paragraph
                    title="Sem problemas!"
                    text="Enviamos seu pedido para nossa base e em breve até 4 profissionais poderão entrar em contato com você. Baixe o App e acompanhe o seu pedido!"
                    size="4"
                    headline
                  />
                </Col>
              </Row>

              <Row className="middle-sm">
                <Col className="col-md-12 center-md">
                  <Picture
                    className="picture"
                    src={{
                      sm: imgPrefix(
                        '/images/contato-instantaneo/img_instant-matching-skip-indications-mobile.svg'
                      ),
                      md: imgPrefix(
                        '/images/contato-instantaneo/qrcode-skip-indications-desktop.svg'
                      ),
                      lg: imgPrefix(
                        '/images/contato-instantaneo/qrcode-skip-indications-desktop.svg'
                      ),
                    }}
                    size={defaultImageSize}
                    alt="Baixe o App"
                  />
                </Col>
              </Row>

              <Row
                className={`${styles.modal_interaction__paragraph_mobile} hide-md`}
              >
                <Col>
                  <Paragraph
                    title="Sem problemas!"
                    text="Enviamos seu pedido para nossa base e em breve até 4 profissionais poderão entrar em contato com você. Baixe o App e acompanhe o seu pedido!"
                    size="3"
                    headline
                  />
                </Col>
              </Row>

              <Row className="hide-md">
                <Col>
                  <br />
                  <Button.Root
                    as="a"
                    href="https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_s[%E2%80%A6]utm_medium=getninjas&utm_campaign=nao_gostei_indicacaoao"
                    className="primary close"
                    size="small"
                    fullWidth
                    onClick={() =>
                      triggerEvent(
                        'instant-match-profile-list__skip-list_download-app',
                        { requestId }
                      )
                    }
                  >
                    Baixar o App
                  </Button.Root>
                </Col>
              </Row>

              <Row className="hide-sm center">
                <Col className="center">
                  <Image
                    src={imgPrefix(
                      '/images/contato-instantaneo/img_instant-matching-stores.svg'
                    )}
                    alt="Baixe o app"
                    width={193.49}
                    height={40}
                    placeholder="blur"
                    blurDataURL={getBlurDataURL()}
                    priority
                  />
                </Col>
              </Row>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
