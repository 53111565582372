// eslint-disable-next-line import/no-unresolved
import { BudgetTemplate } from './Budget/BudgetTemplate';
import { CategoryTemplate } from './Category/CategoryTemplate';
import { ChatListTemplate } from './Chat/ChatListTemplate';
import { FaqArticleTemplate } from './Faq/FaqArticleTemplate';
import { FaqHomeTemplate } from './Faq/FaqHomeTemplate';
import { FaqDeleteAccountTemplate } from './Faq/FaqDeleteAccount';
import { HomeTemplate } from './Home/HomeTemplate';
import { InstantMatchTemplate } from './InstantMatch/InstantMatchTemplate';
import { NotFoundTemplate } from './NotFound/NotFoundTemplate';
import { UnderConstructionTemplate } from './UnderConstruction/UnderConstructionTemplate';
import { PageDownTemplate } from './PageDown/PageDownTemplate';
import { AgreementsTemplate } from './Agreements/AgreementsTemplate';
import { PriceTemplate } from './Price/PriceTemplate';
import { SurveyTemplate } from './Survey/SurveyTemplate';
import { PhoneVerificationTemplate } from './PhoneVerification/PhoneVerificationTemplate';
import { SitemapTemplate } from './Sitemap/SitemapTemplate';
import { RequestTemplate } from './Request/RequestTemplate';
import { DuplicatedRequestTemplate } from './DuplicatedRequest/DuplicatedRequestTemplate';
import { ErrorTemplate } from './ErrorTemplate/ErrorTemplate';
import { AuthTemplate } from './Auth/AuthTemplate';

export {
  BudgetTemplate,
  CategoryTemplate,
  ChatListTemplate,
  FaqArticleTemplate,
  FaqHomeTemplate,
  FaqDeleteAccountTemplate,
  HomeTemplate,
  InstantMatchTemplate,
  NotFoundTemplate,
  PriceTemplate,
  AgreementsTemplate,
  SurveyTemplate,
  PhoneVerificationTemplate,
  SitemapTemplate,
  RequestTemplate,
  DuplicatedRequestTemplate,
  UnderConstructionTemplate,
  PageDownTemplate,
  ErrorTemplate,
  AuthTemplate,
};
